<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title=" " />

    <div class="generalBorderlessBox">
        <div v-if="isLoadProjs === true">
            <div v-if="pageMode === 'proj' && proj === null" class="row">
                <div class="col-12">
                    <div class="generalContentBox border shadow-sm">Folder not found</div>
                </div>
            </div>

            <div v-else-if="pageMode === 'doc' && doc === null" class="row">
                <div class="col-12">
                    <div class="generalContentBox border shadow-sm">Document not found</div>
                </div>
            </div>

            <div v-else class="row">
                <!-- Left column -->
                <div class="col-12 col-lg-8 d-grid">
                    <div class="border shadow-sm generalContentBox">
                        <!-- Left: Folder -->
                        <div class="row mb-2">
                            <div class="col-12">
                                <table class="table table-borderless table-sm mb-0" border="0">
                                    <td width="185px">
                                        <div>
                                            <table class="table table-borderless table-sm mb-0 pb-0" width="100%" border="0">
                                                <tbody>
                                                    <tr class="p-0">
                                                        <td>
                                                            <span>
                                                                <fa icon="folder-open" style="font-size: 100px; color: #E2C299" />
                                                            </span>

                                                            <div style="z-index: 100; position: absolute; margin-top: -49px; margin-left: 70px; line-height: 1.2">
                                                                <div v-if="proj.privacy=== 'PUBLIC'" class="p-0 m-0" style="display: block">
                                                                    <Popper class="popperDark" arrow hover content="Disclose full information" placement="right">
                                                                        <span class="fw-bold lblTiny isTooltipsLabel p-0 m-0" style="display: block">{{ proj.privacy }}</span>
                                                                    </Popper>
                                                                </div>
                                                                <div v-if="proj.privacy=== 'PRIVATE'" class="p-0 m-0" style="display: block; line-height: 1.2">
                                                                    <Popper class="popperDark" arrow hover content="View name, timestamp and status only" placement="right">
                                                                        <span class="fw-bold lblTiny isTooltipsLabel p-0 m-0" style="display: block">{{ proj.privacy }}</span>
                                                                    </Popper>
                                                                </div>
                                                                <div v-if="proj.privacy=== 'CONFIDENTIAL'" class="p-0 m-0" style="display: block; line-height: 1.2">
                                                                    <Popper class="popperDark" arrow hover content="View document name and timestamp only" placement="right">
                                                                        <span class="fw-bold lblTiny isTooltipsLabel p-0 m-0" style="display: block">{{ proj.privacy }}</span>
                                                                    </Popper>
                                                                </div>
                                                            
                                                                <div v-if="proj.mode=== 'SIMPLE'" class="p-0 m-0" style="display: block; line-height: 1.2">
                                                                    <Popper class="popperDark" arrow hover content="Sign with valid email only" placement="right">
                                                                        <span class="fw-bold lblTiny isTooltipsLabel p-0 m-0" style="display: block">{{ proj.mode }}</span>
                                                                    </Popper>
                                                                </div>
                                                                <div v-if="proj.mode=== 'KYC'" class="p-0 m-0" style="display: block; line-height: 1.2">
                                                                    <Popper class="popperDark" arrow hover content="Require to verify identity. You may assign signing sequence" placement="right">
                                                                        <span class="fw-bold lblTiny isTooltipsLabel p-0 m-0" style="display: block">{{ proj.mode }}</span>
                                                                    </Popper>
                                                                </div>
                                                                <div v-if="proj.mode=== 'STRICT'" class="p-0 m-0" style="display: block; line-height: 1.2">
                                                                    <Popper class="popperDark" arrow hover content="You can review the signing process manually, request for footage of signing, upload hardcopy of signed documents later" placement="right">
                                                                        <span class="fw-bold lblTiny isTooltipsLabel p-0 m-0" style="display: block">{{ proj.mode }}</span>
                                                                    </Popper>
                                                                </div>
                                                                
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </td>
                                    <td class="d-none d-md-block">
                                        <span>
                                            <Popper class="popperDark" arrow hover content="Total document">
                                                <span class="badge bg-grey isTooltipsLabel"><fa icon="file" /> {{ totalDoc }}</span>
                                            </Popper>
                                            <span class="fw-bold px-0">{{ proj.folderName }}</span>
                                        </span>
                                    </td>
                                    <td class="text-end pt-1 mb-0 pb-0" width="125px">
                                        <div><ProjStatus :status="proj.status" :shred="proj.shred" :showDesc="true" /></div>
                                        <div class="small mb-2">{{ func.convDateTimeFormat(proj.dateModify, 'text')}}</div>
                                        <table width="100%" border="0">
                                            <tr>
                                                <td width="55%">
                                                    <span v-if="isSignOn === true && (proj.privacy === 'PUBLIC' || pagePrivilege === 'signee')" class="isLink" @click="downloadFolder">
                                                        <div class="p-0 ps-3"><fa icon="download" style="font-size: 35px; float: left" /></div>
                                                        <div class="py-0 small">Download</div>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span class="isLink" @click="shareFolder">
                                                        <div class="p-0"><fa icon="share" style="font-size: 35px" /></div>
                                                        <div class="py-0 small">Share</div>
                                                    </span>
                                                </td>
                                            </tr>
                                        </table>
                                        
                                        
                                    </td>
                                </table>
                            </div>

                            <div class="col-12 d-md-none">
                                <span>
                                    <Popper class="popperDark" arrow hover content="Total document">
                                        <span class="badge bg-grey isTooltipsLabel me-1"><fa icon="file" /> {{ totalDoc }}</span>
                                    </Popper>
                                    <span class="fw-bold">{{ proj.folderName }}</span>
                                </span>
                            </div>

                            <div class="col-12">
                                <div><hr class="my-2" /></div>
                            </div>
                        </div>
                        <!-- Left: Folder end-->
                        <!-- Left: doc -->
                         <div class="row">
                            <div class="col-12 text-grey">
                                <table class="table table-hover" border="0">
                                    <tbody v-if="proj.jDocument">

                                        <!-- Project: Doc list - DocStatus != DELETE -->
                                        <tr v-for="d in proj.jDocument" :key="d">
                                            <td width="25px" class="pb-2">
                                                <div v-if="proj && !(pagePrivilege === 'public' && (proj.privacy === 'PRIVATE' || proj.privacy === 'CONFIDENTIAL')) && d.remark" @click="noteType = 'note'; noteDesc = d.remark" data-bs-toggle="modal" data-bs-target="#mdlNote">
                                                    <div class="lblNote"> 
                                                        <img src="../../assets/label_note.png" class="lblNoteImg" alt="note background" />
                                                        <div class="lblTiny lblNoteDesc" style="margin-bottom: 10px;">Note</div>
                                                    </div>    
                                                </div>
                                                <div v-if="proj && !(pagePrivilege === 'public' && (proj.privacy === 'PRIVATE' || proj.privacy === 'CONFIDENTIAL')) && proj.isEditor === true && d.note" @click="noteType = 'editor'; noteDesc = d.note" data-bs-toggle="modal" data-bs-target="#mdlNote">
                                                    <div class="lblNote">
                                                        <img src="../../assets/label_note.png" class="lblNoteImg" alt="note background" />
                                                        <div class="lblTiny lblNoteDesc">Editor</div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="pb-2">
                                                <div class="row">
                                                    <!-- <div class="col-12 col-lg-6"> -->
                                                    <div class="col-5">
                                                        <div class="row">
                                                            <div v-if="d.status === 'DELETE'" class="col-3 mb-2">
                                                                <fa icon="file" style="font-size: 45px" />
                                                                <div v-if="d.jSignee.length > 0" style="z-index: 50; position: absolute; margin-top: -30px; margin-left: 0px; width: 34px" class="text-white text-center small">
                                                                    {{ d.progress }}%
                                                                </div>
                                                            </div>
                                                            <div v-else class="col-3 mb-2 isLink" @click="$router.push({ path: '/p/' + projId + '/' + d.documentId, query: route.query})">
                                                                <fa icon="file" style="font-size: 45px" />
                                                                <div v-if="d.jSignee.length > 0" style="z-index: 50; position: absolute; margin-top: -30px; margin-left: 0px; width: 34px" class="text-white text-center small">
                                                                    {{ d.progress }}%
                                                                </div>
                                                            </div>
                                                            <div class="col-9 mb-2 ps-4">
                                                                <!-- Large screen -->
                                                                <div class="small text-grey mt-1 d-none d-sm-block">
                                                                    {{ func.convSize(d.size) }}<fa icon="circle" class="px-1" style="font-size: 4px; padding-bottom: 3px" />{{d.totalPage}} page<span v-if="d.totalPage > 1">s</span>
                                                                </div>
                                                                <!-- Small screen -->
                                                                <div class="small d-sm-none text-grey">{{ func.convSize(d.size) }}<fa icon="circle" class="ps-1" style="font-size: 4px; padding-bottom: 3px" /></div>
                                                                <div class="small d-sm-none text-grey">{{d.totalPage}} page<span v-if="d.totalPage > 1">s</span></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- <div class="col-12 col-lg-6"> -->
                                                    <div class="col-7">
                                                        <table width="100%" align="right" border="0">
                                                            <tr>
                                                                <td class="text-end">
                                                                    <span v-if="d.status === 'DELETE'" class="fw-bold text-orange">DELETED</span>
                                                                    <span v-else-if="d.shred === 'PENDING'" class="fw-bold text-orange">PENDING SHRED</span>
                                                                    <span v-else-if="d.isRejected === true" class="fw-bold">
                                                                        <span class="text-danger">REJECTED</span>
                                                                        <div v-if="d.jUSignee.signingStatus === 'REJECTED'">By me</div>
                                                                    </span>
                                                                    <span v-else-if="d.progress === 100" class="fw-bold text-pink">{{ d.status }}</span>
                                                                    <span v-else-if="d.jUSignee.signingStatus !== undefined">
                                                                        <span v-if="d.jUSignee.signingStatus === 'PENDINGSIGN'">
                                                                            <div v-if="pagePrivilege === 'public'" class="fw-bold text-success">IN PROGRESS</div>
                                                                            <button v-else-if="userStatus === 'PENDING'" class="btn btn-sm btn-info text-white" data-bs-toggle="modal" data-bs-target="#mdlActivate">
                                                                                <fa icon="signature" class="me-1" />SIGN NOW
                                                                            </button>
                                                                            <button v-else class="btn btn-sm btn-info text-white" @click="$router.push({ path: '/p/' + projId + '/' + d.documentId, query: route.query})">
                                                                                <fa icon="signature" class="me-1" />SIGN NOW
                                                                            </button>
                                                                        </span>
                                                                        <span v-else-if="d.jUSignee.signingStatus === 'PENDING'" class="fw-bold text-success">IN PROGRESS</span>
                                                                        <span v-else-if="d.jUSignee.signingStatus === 'APPROVED'" class="fw-bold">
                                                                            <span class="text-success">APPROVED</span>
                                                                            <div v-if="d.jUSignee.signingStatus === 'APPROVED'">By me</div>
                                                                        </span>
                                                                        <span v-else class="fw-bold">{{ d.jUSignee.signingStatus }}</span>
                                                                    </span>
                                                                    <span v-else class="fw-bold">
                                                                        <span v-if="d.status === 'SIGNED' || d.status === 'INVITED'" class="text-success">IN PROGRESS</span>
                                                                        <span v-else>
                                                                            <span v-if="d.status === 'ALLSIGNED'" class="text-pink">{{ d.status }}</span>
                                                                            <!-- <span v-if="d.status === 'INVITED' || d.status === 'SIGNED'" class="text-success">{{ d.status }}</span> -->
                                                                            <span v-if="d.status === 'DRAFT'" class="text-info">{{ d.status }}</span>
                                                                            <span v-if="d.status === 'VIEWONLY'" class="text-brown">{{ d.status }}</span>
                                                                            <span v-if="d.status === 'DELETE'" class="text-orange">{{ d.status }}</span>
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2" class="text-end small">{{ func.convDateTimeFormat(d.dateModify, 'text')}}</td>
                                                            </tr>
                                                        </table>
                                                    </div>

                                                    <div class="col-12 col-lg-12">
                                                        <table width="100%" border="0">
                                                            <tr>
                                                                <td>
                                                                    <Popper class="popperDark" arrow hover content="Total document signee">
                                                                        <span class="badge bg-grey isTooltipsLabel"><fa icon="user" /> {{ d.jSignee.length }}</span>
                                                                    </Popper>
                                                                    <span v-if="d.status === 'DELETE'" class="fw-bold ms-1">{{ d.name.replace('.pdf','') }}</span>
                                                                    <span v-else class="fw-bold ms-1 isLink" @click="$router.push({ path: '/p/' + projId + '/' + d.documentId, query: route.query})">{{ d.name.replace('.pdf','') }}</span>
                                                                </td>
                                                                <td v-if="d.status !== 'DELETE'" width="40px" class="align-bottom">
                                                                    <span v-if="showDocId === d.documentId" class="float-end px-1 isLink" @click="showDocId = ''">
                                                                        <fa icon="chevron-up" size="lg"/>
                                                                    </span>
                                                                    <span v-else class="float-end px-1 isLink" @click="doc = null; showDocId = d.documentId; getDocument(d.documentId, true)">
                                                                        <fa icon="chevron-down" size="lg"/>
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>

                                                <!-- Left: signee list -->
                                                <div class="row pt-2" :class="showDocId === d.documentId ? '' : 'd-none'">
                                                    <div v-if="doc && doc.jSignee && (proj.privacy === 'PUBLIC' || proj.privacy === 'PRIVATE' || (proj.privacy === 'CONFIDENTIAL' && pagePrivilege === 'signee'))" class="col-12 tblSigneeList">
                                                        <table class="table table-borderless table-hover mb-0" border="0">
                                                            <tbody>
                                                                <tr v-for="s in doc.jSignee" :key="s">
                                                                    <td class="border-top">
                                                                        <span v-if="s.role !== ''" class="badge bg-grey">{{s.role}}</span>
                                                                        <!-- <span class="badge bg-grey">YN-TMP</span> -->
                                                                    </td>
                                                                    <td class="border-top">
                                                                        <div class="row">
                                                                            <div class="col-lg-8">
                                                                                <table class="table table-borderless table-sm m-0 pb-0" border="0">
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td v-if="doc.isSort === true" class="pe-3">{{ s.seq }}</td>
                                                                                            <td width="50px">
                                                                                                <ImgAvatar :isUser="s.isUser" :id="s.jAvatar.userId" displayName="" :isVerified="s.isVerified" isActive="" :privilege="s.privilege" align="center" width="35" height="35" />
                                                                                            </td>
                                                                                            <td>
                                                                                                <div v-if="s.signedName.trim() !== s.signeeName.trim() && s.signedName.trim() !== '' && s.signeeName.trim() !== ''">
                                                                                                    <span>{{ s.signedName }}</span>
                                                                                                    <span class="text-grey"><fa icon="at" class="mx-1"/>{{s.signeeName}}</span>
                                                                                                </div>
                                                                                                <div v-else>
                                                                                                    <span v-if="s.signedName.trim() !== ''">{{ s.signedName }}</span>
                                                                                                    <span v-else-if="s.signeeName.trim() !== ''">{{ s.signeeName }}</span>
                                                                                                </div>
                                                                                                <div class="lblTiny text-grey">{{s.signeeId}}</div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                            <div class="col-lg-4 text-end">
                                                                                <div>
                                                                                    <span v-if="isLogin === true && doc.jUSignee && doc.jUSignee.isDocumentSignee === true && s.signeeId === getUserId && doc.jUSignee.signingStatus === 'PENDINGSIGN' && s.signingStatus === 'PENDINGSIGN'">
                                                                                        <div v-if="pagePrivilege === 'public'">NOT SIGNED</div>
                                                                                        <div v-else>SIGN NOW</div>
                                                                                    </span>
                                                                                    <span v-else-if="s.signingStatus === 'REJECTED'" class="text-danger">
                                                                                        <span v-if="s.comment">
                                                                                            <Popper class="popperDark" arrow hover :content="s.comment">
                                                                                                <fa icon="clipboard" class="me-2" />
                                                                                            </Popper>
                                                                                        </span>
                                                                                        <span class="fw-bold">REJECTED</span>
                                                                                        <span v-if="pagePrivilege === 'signee' && (proj.isEditor === true || s.signeeId === getUserId)" class="isLink" data-bs-toggle="modal" data-bs-target="#mdlDelReject" @click="cancelRejectDocId = d.documentId">
                                                                                            <Popper class="popperDark" arrow hover content="Click to clear REJECTED status">
                                                                                                <fa icon="trash" class="text-primary ms-2" />
                                                                                            </Popper>
                                                                                        </span>
                                                                                    </span>
                                                                                    <span v-else-if="s.signingStatus === 'PENDINGSIGN' || s.signingStatus === 'PENDING'">NOT SIGNED</span>
                                                                                    <span v-else>
                                                                                        {{ s.signingStatus }}
                                                                                        <span v-if="s.comment !== null && s.comment !== ''">
                                                                                            <Popper class="popperDark" arrow hover :content="s.comment">
                                                                                                <fa icon="clipboard" class="me-2" />
                                                                                            </Popper>
                                                                                        </span>
                                                                                    </span>
                                                                                    <!-- ORIG <span v-else class="fw-bold">{{ s.signingStatus }}</span> -->
                                                                                </div>
                                                                                <div v-if="s.dateSigned !== '0' && s.dateSigned !== undefined" class="text-end small">{{ func.convDateTimeFormat(s.dateSigned, 'text')}}</div>
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <!-- Left: signee list end -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                         </div>
                        <!-- Left: doc end-->
                        <div class="row">
                            <div class="col-12" v-if="showDocId === ''"><Legend folderStatus="true" docStatus="false" /></div>
                            <div class="col-12" v-else><Legend folderStatus="false" docStatus="true" /></div>
                        </div>
                    </div>
                </div>
                <!-- End of Left column -->

                <!-- Right column -->
                <div v-if="proj" class="col-12 col-lg-4 d-grid">
                    <div v-if="isLogin === false" class="shadow-sm whiteBox">
                        <div class="row">
                            <div class="col-16 fw-bold mb-2"><fa icon="circle-question" size="lg" class="me-2" />Why i am not able to sign?</div>
                            <div class="col-16 mb-2" style="text-align: justify; text-justify: inter-word">Signature can be forged physically or digitally (aka Picture Signature). It is important to sign a document with a validated email to ensure you are the actual signee. SignOn traces the signing process with full Audit Report.</div>
                            <div class="col-16" style="text-align: justify; text-justify: inter-word">Sign up now and sign documents securely with peace of mind.</div>
                        </div>
                    </div>
                    <div v-if="isLogin === false" class="shadow-sm blueBox border-top-0">
                        <table class="table table-borderless mb-0" border="0">
                            <tbody>
                                <tr>
                                    <td>
                                        <div style="padding-top: 10px">
                                            <fa icon="signature" class="bg-light text-secondary" style="font-size: 30px; padding: 18px 14px; border-radius: 32px" />
                                        </div>
                                    </td>
                                    <td>
                                        <div class="mb-2 text-white"><span class="fw-bold">It's always free to sign. </span>Get yourself a free account today.</div>
                                        <div class="text-end">
                                            <button type="button" class="btn btn-secondary" @click="$router.push({ path: '/auth/signup' })">Sign Up</button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                        <div class="text-left fw-bold text-white">
                            Existing subscriber?
                            <button type="button" class="btn btn-secondary float-end" @click="$router.push({ path: '/auth/login', query: { redirect: 'InfoProj', p: projId}})">Login</button>
                        </div>
                    </div>

                    <div v-if="proj && proj.isEditor === true" style="margin-bottom: 25px; background-color: #5B9BD5; border-radius: 10px; padding: 25px;">
                        <table class="table table-borderless table-sm m-0" border="0">
                            <tr>
                                <td colspan="3" class="text-center text-white">View document as a different role</td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="d-grid">
                                        <div class="btn-group btn-group-sm btn-block">
                                            <button v-if="proj.status === 'WIP'" @click="router.push('/uploadDoc/' + projId)" type="button" class="btn btn-sm" :class="pagePrivilege === 'editor'? 'btn-info text-white' : 'bg-white border'">
                                                <fa icon="pencil" class="me-2"/>Editor
                                            </button>
                                            <button v-if="proj.status === 'CIRCULATING' || proj.status === 'SEALED'" @click="pagePrivilege = 'editor'" data-bs-toggle="modal" data-bs-target="#mdlModify" type="button" class="btn btn-sm" :class="pagePrivilege === 'editor'? 'btn-info text-white' : 'bg-white border'">
                                                <fa icon="pencil" class="me-2"/>Editor
                                            </button>
                                            <button type="button" @click="pagePrivilege = 'signee'" class="btn btn-sm" :class="pagePrivilege === 'signee'? 'btn-info text-white' : 'bg-white border'">
                                                <fa icon="user" class="me-2"/>Signee
                                            </button>
                                            <button type="button" @click="pagePrivilege = 'public'" class="btn btn-sm" :class="pagePrivilege === 'public'? 'btn-info text-white' : 'bg-white border'">
                                                <fa icon="eye" class="me-2"/>Public
                                            </button>
                                        </div> 
                                    </div> 
                                </td>
                                <td v-if="showDocId === ''" width="30px" class="text-end">
                                    <span class="isLink" data-bs-toggle="modal" data-bs-target="#mdlDelFolder">
                                        <Popper class="popperDark" arrow hover content="Shred folder">
                                            <fa icon="trash" size="lg" class="text-primary" />
                                        </Popper>
                                    </span>
                                </td>
                                <td v-if="showDocId !== ''" width="30px" class="text-end">
                                    <span class="isLink" data-bs-toggle="modal" data-bs-target="#mdlAddNote">
                                        <Popper class="popperDark" arrow hover content="Add note">
                                            <fa icon="clipboard" size="lg" class="text-primary" />
                                        </Popper>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <!-- <div v-if="showDocId === '' && (proj.privacy === 'PUBLIC' || pagePrivilege === 'signee')" class="border shadow-sm generalContentBox">
                        folder - view
                    </div> -->

                    <div v-if="isSignOn === true && showDocId !== '' && docShredApproval === 'PENDING' && doc !== null && doc !== undefined && (proj.privacy === 'PUBLIC' || pagePrivilege === 'signee')" class="border shadow-sm generalContentBox">
                         <table width="100%" border="0">
                            <tr >
                                <td class="align-top" width="60px"><fa icon="circle-exclamation" style="font-size: 45px" /></td>
                                <td class="pb-2">
                                    <div class="fw-bold mb-2">Shred approval request</div>
                                    <div>This document in your assigned folder requires your prompt approval for a shred request.</div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" class="text-end">
                                    <button class="btn btn-secondary btn-sm me-1" data-bs-toggle="modal" :data-bs-target="'#mdlShredReject'" style="width: 80px">Reject</button>
                                    <button class="btn btn-outline-secondary btn-sm" data-bs-toggle="modal" :data-bs-target="'#mdlShredApprove'" style="width: 80px">Shred</button>
                                </td>
                            </tr>
                         </table>

                    </div>

                    <div class="border shadow-sm generalContentBox">
                        <div class="row">
                            <div v-if="proj.jEntity" class="col-12 mb-2">
                                <ImgLogo :id="proj.jEntity.jLogo.entityId" :status="proj.jEntity.status" :isVerified="proj.jEntity.isVerified" :width="proj.jEntity.jLogo.width" :height="proj.jEntity.jLogo.height" />
                                <!-- <Logo :status="proj.jEntity.status" :isVerified="proj.jEntity.isVerified" :url="proj.jEntity.jLogo.imgBase64" :width="proj.jEntity.jLogo.width" :height="proj.jEntity.jLogo.height" /> -->
                                <div class="mt-1 fw-bold">{{proj.jEntity.entityName}}</div>
                            </div>

                            <div v-if="proj.privacy === 'PUBLIC' || proj.privacy === 'PRIVATE' || (proj.privacy === 'CONFIDENTIAL' && pagePrivilege === 'signee')" class="col-12 mb-1">
                                <div class="row">
                                    <div v-if="projOwnerEditor" class="col-12">
                                        <table class="table table-borderless table-sm mb-0" border="0">
                                            <tbody>
                                                <tr v-for="o in projOwnerEditor" :key="o">
                                                    <td width="80px" class="align-top lblTiny">{{o.privilege}}</td>
                                                    <td width="50px">
                                                        <ImgAvatar :isUser="o.isUser" :id="o.jAvatar.userId" displayName="" :isVerified="o.isVerified" :isActive="o.status" :privilege="o.privilege" align="center" width="35" height="35" />
                                                        <!-- <Avatar :isUser="o.isUser" :avatar="o.jAvatar.imgBase64" :isVerified="o.isVerified" :isActive="o.status" :privilege="o.privilege" width="35" height="35" align="center" /> -->
                                                    </td>
                                                    <td>
                                                        <div>{{o.signeeName}}</div>
                                                        <div class="lblTiny text-grey">{{o.signeeId}}</div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="col-12 mb-2"><hr class="my-0" /></div>
                                </div>
                            </div>

                            <div v-if="pageView === 'proj' && (proj.privacy === 'PUBLIC' || proj.privacy === 'PRIVATE' || (proj.privacy === 'CONFIDENTIAL' && pagePrivilege === 'signee'))" class="col-12 mb-2">
                                <!-- For project -->

                                <div v-if="proj.jSignee !== undefined" class="row">
                                    <div class="col-12 fw-bold mb-2">Signee ({{ proj.jSignee.length }})</div>
                                    <div class="col-12 mb-2">
                                        <table class="table table-borderless table-sm mb-0" border="0">
                                            <tbody>
                                                <tr v-for="s in proj.jSignee.slice(0, 3)" :key="s">
                                                    <td width="80px" class="align-top">
                                                        <span class="badge bg-grey">{{s.role}}</span>
                                                    </td>
                                                    <td width="50px">
                                                        <ImgAvatar :isUser="s.isUser" :id="s.jAvatar.userId" displayName="" :isVerified="s.isVerified" :isActive="s.status" :privilege="s.privilege" align="center" width="35" height="35" />
                                                        <!-- <Avatar :isUser="s.isUser" :avatar="s.jAvatar.imgBase64" :isVerified="s.isVerified" :isActive="s.status" :privilege="s.privilege" width="35" height="35" align="center" /> -->
                                                    </td>
                                                    <td>
                                                        <div>{{s.signeeName}}</div>
                                                        <div class="lblTiny text-grey">{{s.signeeId}}</div>
                                                    </td>
                                                </tr>

                                                <tr v-for="s in proj.jSignee.slice(3, proj.jSignee.length)" :key="s">
                                                    <td v-if="showSignee === true" class="align-top">
                                                        <span class="badge bg-grey">{{s.role}}</span>
                                                    </td>
                                                    <td v-if="showSignee === true" class="text-center">
                                                        <ImgAvatar :isUser="s.isUser" :id="s.jAvatar.userId" displayName="" :isVerified="s.isVerified" :isActive="s.status" :privilege="s.privilege" align="center" width="35" height="35" />
                                                        <!-- <Avatar :isUser="s.isUser" :avatar="s.jAvatar.imgBase64" :isVerified="s.isVerified" :isActive="s.status" :privilege="s.privilege" width="35" height="35" align="center" /> -->
                                                    </td>
                                                    <td v-if="showSignee === true">
                                                        <div>{{s.signeeName}}</div>
                                                        <div class="lblTiny text-grey">{{s.signeeId}}</div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colspan="3">
                                                        <div class="col-12 small text-primary fst-italic">
                                                            <span v-if="proj.jSignee.length > 3 && showSignee === false" class="float-end isLink" @click="showSignee = true">+ {{proj.jSignee.length-3}} more</span>
                                                            <span v-if="proj.jSignee.length > 3 && showSignee === true" class="float-end isLink" @click="showSignee = false">less</span>
                                                        </div>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                            </div>
                            <div v-if="pageView === 'doc' && (proj.privacy === 'PUBLIC' || proj.privacy === 'PRIVATE' || (proj.privacy === 'CONFIDENTIAL' && pagePrivilege === 'signee'))" class="col-12">
                                <!-- For document -->
                                <div class="row">
                                    <div v-if="docViewer && docViewer.length > 0" class="col-12 fw-bold mb-2">Other Viewer ({{ docViewer.length }})</div>
                                    <div v-else class="col-12">No other viewer</div>
                                    <div v-if="docViewer && docViewer.length > 0" class="col-12 mb-2">
                                        <table class="table table-borderless table-sm mb-0" >
                                            <tbody>
                                                <tr v-for="v in docViewer.slice(0, 3)" :key="v">
                                                    <td width="80px" class="align-top">
                                                        <span class="badge bg-grey">{{v.role}}</span>
                                                    </td>
                                                    <td width="50px">
                                                        <ImgAvatar :isUser="v.isUser" :id="v.jAvatar.userId" displayName="" :isVerified="v.isVerified" :isActive="v.status" :privilege="v.privilege" align="center" width="35" height="35" />
                                                        <!-- <Avatar :isUser="v.isUser" :avatar="v.jAvatar.imgBase64" :isVerified="v.isVerified" :isActive="v.status" :privilege="v.privilege" width="35" height="35" align="center" /> -->
                                                    </td>
                                                    <td>
                                                        <div>{{v.signeeName}}</div>
                                                        <div class="lblTiny text-grey">{{v.signeeId}}</div>
                                                    </td>
                                                </tr>

                                                <tr v-for="v in docViewer.slice(3, docViewer.length)" :key="v">
                                                    <td v-if="showViewer === true" class="align-top">
                                                        <span class="badge bg-grey">{{v.role}}</span>
                                                    </td>
                                                    <td v-if="showViewer === true" >
                                                        <ImgAvatar :isUser="v.isUser" :id="v.jAvatar.userId" displayName="" :isVerified="v.isVerified" :isActive="v.status" :privilege="v.privilege" align="center" width="35" height="35" />
                                                        <!-- <Avatar :isUser="v.isUser" :avatar="v.jAvatar.imgBase64" :isVerified="v.isVerified" :isActive="v.status" :privilege="v.privilege" width="35" height="35" align="center" /> -->
                                                    </td>
                                                    <td v-if="showViewer === true">
                                                        <div>{{v.signeeName}}</div>
                                                        <div class="lblTiny text-grey">{{v.signeeId}}</div>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colspan="3" class="small text-primary fst-italic">
                                                        <span v-if="docViewer.length > 3 && showViewer === false" class="float-end isLink" @click="showViewer = true">+ {{docViewer.length-3}} more</span>
                                                        <span v-if="docViewer.length > 3 && showViewer === true" class="float-end isLink" @click="showViewer = false">less</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div><!-- row -->
                    </div>
                    <!-- Move to top as icon 
                    <div v-if="isSignOn === true && showDocId === '' && (proj.privacy === 'PUBLIC' || pagePrivilege === 'signee')" class="border shadow-sm generalContentBox">
                        <table width="100%" border="0">
                            <tr>
                                <td width="60px"><fa icon="download" style="font-size: 45px" /></td>
                                <td>
                                    <div class="mb-2">Download folder in zip file</div>
                                    <div class="text-end">
                                        <button class="btn btn-secondary" disabled="true" @click="downloadFolder()">Download folder/audit</button>
                                    </div> 
                                </td>
                            </tr>
                        </table>
                    </div> -->

                    <div v-if="showDocId === '' && proj.allowToSeal === true" class="border shadow-sm generalContentBox">
                        <table width="100%" border="0">
                            <tr>
                                <td width="60px"><fa icon="stamp" style="font-size: 45px" /></td>
                                <td>
                                    <div class="mb-2">All documents are fully signed by all parties. You may seal the Folder for safekeeping now</div>
                                    <div class="text-end">
                                        <button class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#mdlSealConfirm">Seal folder</button>
                                    </div> 
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div v-if="showDocId !== '' && doc !== null && doc !== undefined && doc.jFile.dlUrl !== undefined && (proj.privacy === 'PUBLIC' || pagePrivilege === 'signee')" class="border shadow-sm generalContentBox">
                        <table width="100%" border="0">
                            <tr>
                                <td width="60px"><fa icon="download" style="font-size: 45px" /></td>
                                <td>
                                    <div class="mb-2">Document and audit report</div>
                                    <div class="text-end">
                                        <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown">
                                            Download document/audit
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end" style="font-size: 14px">
                                            <li><a class="dropdown-item" href="#" @click="downloadDoc"><fa icon="file-signature" class="me-1" />Original Signed Version</a></li>
                                            <li><hr class="dropdown-divider"></li>
                                            <li v-if="doc.jFile.dlAuditRptUrl !== undefined && doc.status !== 'VIEWONLY'">
                                                <a class="dropdown-item" href="#" @click="downloadAuditLog"><fa icon="file-alt" class="me-2" />Audit Report</a>
                                            </li>
                                        </ul>
                                    </div> 
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div v-if="showDocId !== '' && doc !== null && doc !== undefined && doc.fingerprint !== undefined && doc.fingerprint !== ''" class="border shadow-sm generalContentBox">
                        
                        <table width="100%" border="0">
                            <tr>
                                <td width="60px" class="align-top"><fa icon="fingerprint" style="font-size: 45px" /></td>
                                <td>
                                    <div class="fw-bold mb-1">Fingerprint</div>
                                    <div class="text-break mb-1">{{doc.fingerprint}}</div>
                                    <div class="text-end small">{{ func.convDateTimeFormat(doc.dateFingerprint, 'text')}}</div>
                                </td>
                            </tr>
                        </table>

                        <div><hr /></div>

                        <div class="pb-3">Fingerprint reassures your document is the original signed version without tampering.</div>
                        <div v-if="showFPUpload === true" class="mb-2">
                            <DropZone @drop.prevent="droppedFile" @change="selectedFile" :isFingerprint="true" />
                        </div>

                        <div v-if="showFPUpload === false && showFPResult === true" class="mb-3">
                            <div class="pb-2">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped" :style="{width: + fPProgress + '%'}">{{fPProgress}} % </div>
                                </div>
                            </div>

                            <div class="p-3" style="background-color: var(--auth-box-bg); border-radius: 10px;">
                                <div class="pb-2 fw-bold">Result</div>
                                <div v-if="fPResult === 'Fingerprint matches with the Original Signed Version.'" class="pb-2"><fa icon="circle-check" size="lg" class="me-1 text-success" />{{fPResult}}</div>
                                <div v-if="fPResult === 'Wrong fingerprint. Either you uploaded wrong file or your file is tampered.' || fPResult === 'No fingerprint.'" class="pb-2"><fa icon="circle-xmark" size="lg" class="me-1 text-danger" />{{fPResult}}</div>
                                <div class="pb-2 text-end">
                                    <span @click="showFPUpload = true; showFPResult= false; fPProgress = 0" class="text-primary isLink">Reset</span>
                                </div>
                            </div>
                        </div>

                    </div><!-- Fingerprint end -->
                </div>
                <!-- End of Right column -->

                <div v-if="showDocId === ''" class="col-12 col-lg-8">
                    <span v-if="route.query.fr === 'overview'" @click="$router.push({ path: '/project/' + projId })" class="text-primary isLink">
                        <fa icon="chart-line" size="lg" class="me-2" />Folder Overview
                    </span>
                    <span v-else @click="$router.push({ path: '/doc/verify' })" class="text-primary isLink">
                        <fa icon="check-double" size="lg" class="me-2" />Verify New Doc
                    </span>
                </div>
                <!-- <div v-if="showDocId !== ''" class="col-12 col-lg-8">
                    <span @click="docId = ''" class="text-primary isLink">
                        <fa icon="folder" size="lg" class="me-2" />Back to Folder
                    </span>
                </div> -->
            </div>
        </div>

        <div v-else>
            <div class="row">
                <div class="col-12">
                    <div class="generalContentBox border shadow-sm"><fa icon="spinner" size="lg" class="me-2" />Loading...</div>
                </div>
            </div>

            <div v-if="isCookieEnable === false" class="row">
                <div class="col-12 text-grey pt-3">
                    Cookies disabled. We encourage to enable cookies for better user experience.
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: Confirm to remove reject --> 
    <div class="modal fade" id="mdlDelReject">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Confirm to clear and remove REJECTED status?
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="cancelReject()">
                        <fa icon="trash" size="lg" class="me-2" />Remove
                    </button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: Resend Activation--> 
    <div class="modal fade" id="mdlResendActivation">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Resend Activation Email</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 pb-2">
                            Fill in detail below to resend activation link
                        </div>

                        <div class="col-12 pb-2">
                            <input v-model="email" class="form-control" placeholder="Email">
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <div style="width: 200px; height: 100px">
                                <img :src="captchaImgResend" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                <button class="btn btn-secondary btn-sm mb-3" @click="getCaptchaResend" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                    <Popper class="popperDark" arrow hover content="Refresh Captcha">
                                        <fa icon="refresh" />
                                    </Popper>
                                </button>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <input v-model="captchaResend" class="form-control" placeholder="Captcha">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                    <button type="button" class="btn btn-secondary" @click="resendActivation">
                        <fa icon="envelope" size="lg" class="me-2" />Resend
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: Activation required --> 
    <div class="modal fade" id="mdlActivate">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Email Validation Required</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 pb-2">One last step to sign a document.</div>
                        <div class="col-12 pb-2">Signature can be forged physically or digitally (aka Picture Signature). It is important to sign a document with a validated email to ensure you are the actual signee. SignOn traces the signing process with full Audit Report.</div>
                        <div class="col-12">Please validate your email {{getUserId}} from your INBOX. If you do not receive the validation email, please request again <span class="isLink text-info" data-bs-toggle="modal" data-bs-target="#mdlResendActivation" @click="getCaptchaResend">here</span>.</div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: Delete confirmation --> 
    <div class="modal fade" id="mdlDelFolder">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Shred folder</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-2">
                            Confirm to shred folder?
                        </div>
                        <div>Document inside folder is unrecoverable after shreded.</div>
                    </div>
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button class="btn btn-secondary" @click="delProj" data-bs-dismiss="modal">
                        <fa icon="trash" class="me-2" />Shred
                    </button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: Seal folder confirmation --> 
    <div class="modal fade" id="mdlSealConfirm">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Seal folder</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            Confirm to seal the folder?
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="sealFolder">
                        <fa icon="stamp" class="me-2" />Seal folder
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: Modify CIRCULATING/SEALED project status --> 
    <div
      class="modal fade"
      id="mdlModify"
      ref="mdlModify"
    >
      <div class="modal-dialog modal-dialog-centered text-dark">
          <div class="modal-content">
              <div class="modal-header border-0">
                  <h5 v-if="proj && proj.status" class="modal-title" id="exampleModalLabel"> Modify {{ proj.status.substr(0,1) + proj.status.slice(1).toLowerCase() }} Folder</h5>
                  <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                    <div class="mb-2">Modify folder now will block all signees from accessing the documents in the folder. You will need to send Sign Resend again.</div>
                    <div>Confirm to proceed?</div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="proceedModify" data-bs-dismiss="modal">
                    <fa icon="check" class="me-2" />Yes
                </button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                    <fa icon="xmark" class="me-2" />Cancel
                </button>
              </div>
          </div>
      </div>
    </div>

    <!-- Modal: Note/editor --> 
    <div
      class="modal fade"
      id="mdlNote"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 v-if="noteType === 'editor'" class="modal-title" id="exampleModalLabel">Private note to editor(s) only</h5>
                    <h5 v-else class="modal-title" id="exampleModalLabel">Public editor note to signee(s)</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-html="noteDesc.replaceAll('\n', '<br />')"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>

    <!-- Modal: Add note --> 
    <div class="modal fade" id="mdlAddNote">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel"> Add Note</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12 mb-3">
                            <label class="form-label text-secondary"><fa icon="clipboard" size="lg" class="text-brown px-1 me-2" />Editor note to signee(s)</label>
                            <textarea v-model="publicNote" rows="5" class="form-control"></textarea>
                        </div>
                        <div class="col-12">
                            <label class="form-label text-secondary"><fa icon="note-sticky" size="lg" class="text-brown me-2" />Private note to editor(s) only</label>
                            <textarea v-model="selfNote" rows="2" class="form-control"></textarea>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Cancel
                    </button>
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="addNote">
                        <fa icon="floppy-disk" size="lg" class="me-2" />Save
                    </button>
                </div>
            </div><!-- modal-content -->
        </div><!-- modal-dialog -->
    </div><!-- Modal End-->

    <!-- Modal: Attachment filename --> 
    <div
        class="modal fade"
        id="mdlRejectedAttachment"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Attachment filename 1</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="p-5 text-center bg-grey text-white border">
                                Sample Image
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End-->

    <!-- Modal: Confirm to reject shred approval --> 
    <div class="modal fade" id="mdlShredReject">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Confirm to reject shred request?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="shredReject()">
                        <fa icon="ban" size="lg" class="me-2" />Reject
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal: Confirm to reject shred approval End -->

    <!-- Modal: Confirm to approve/agree shred approval --> 
    <div class="modal fade" id="mdlShredApprove">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Confirm to approve shred request?
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="shredApprove()">
                        <fa icon="trash" size="lg" class="me-2" />Shred
                    </button>
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal: Confirm to approve/agree shred approval End -->

    <!-- Modal: Confirm to cancel reject shred approval --> 
    <div class="modal fade" id="mdlShredCancelReject">
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    Confirm to cancel for reject shred?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />Close
                    </button>
                    <button class="btn btn-secondary" data-bs-dismiss="modal" @click="shredCancelReject()">
                        <fa icon="ban" size="lg" class="me-2" />Cancel Reject
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal: Confirm to cancel reject shred approval End -->

</template>

<script>
import { ref, inject, onMounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import Popper from 'vue3-popper'
import DocStatus from '@/components/DocStatus.vue'
import funcs from '@/functions/function'
// import Avatar from '@/components/Avatar.vue'
// import Logo from '@/components/Logo.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'
import ImgLogo from '@/components/ImgLogo.vue'
import ProjStatus from '@/components/ProjStatus.vue'
import DropZone from '@/components/DropZone.vue'
import Legend from '@/components/Legend.vue'

export default {
    name: 'ViewProjMain',
    components: { 
        TopNavigation, Alert, Popper, DocStatus, /* Avatar, Logo, */ ImgAvatar, ImgLogo, ProjStatus, DropZone, Legend
    },
    setup() {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const isSignOn = ref(false) // detect if from signon Entity

        const isLogin = ref(false) // pulic / login user
        const userStatus = ref(false) // Detect user status: pending, active etc
        const privacy = ref(null)  // folder privacy, proj.privacy

        const projId = ref('')
        const docId = ref('')
        const showDocId = ref('') // user click doc to view signee
        const pageMode = ref('') // proj/doc (access method: /p/:projectId / or /d/:documentId)
        const pageView = ref('') // /proj/doc (loading/ folder view / document view)
        const pagePrivilege = ref('') // for owner/editor, can access as public/signee/editor 
        const projName = ref(null)

        const proj = ref(null)  // test
        const isLoadProjs = ref(false)   // to indicate has load the project, projects cannot set to null by default
        const doc = ref(null) // test
        const showSignee = ref(false)
        const showRejected = ref(false)
        // const hasDelDoc = ref(false)
        const totalDoc = ref(0) // excluded status = DELETE
        const showViewer = ref(false)   // show more for doc viewer
        const docViewer = ref([])
        const projOwnerEditor = ref([]) // list of owner and editor
        const noteType = ref('')
        const noteDesc = ref('')
        const cancelRejectDocId = ref(null)

        const docShredApproval = ref(false)
        const docShredApprovalDate = ref('')

        const showFPUpload = ref(true)  // true/false show/hide fingerprint drga and drop
        const showFPResult = ref(false) // true/false show/hide fingerprint compare result
        const fPResult = ref(null)        // fingerprint compare result
        const fPProgress = ref(0)        // fingerprint upload progress

        const publicNote = ref(null)
        const selfNote = ref(null)
        const mdlModify = ref(null)
        const num = ref(-1)
        const captchaImgResend = ref(null)
        const refIdResend = ref(null)
        const captchaResend = ref(null)
        const email = ref(null)

        const { getUserId } = useStore()
        const store = useStore()

        const isCookieEnable = ref(null) // chk if cookie / web storage enable

        watch([docId], () => {
            if (docId.value === '' && projId.value === '') {
                pageView.value = ''

            } else {
                if (docId.value !== '') {
                    pageView.value = 'doc'

                } else {
                    pageView.value = 'proj'
                }
            }
        })


        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        const shredReject = () => {
            axios.put( '/signon/signee/shred/' + projId.value + '/' + docId.value + '/REJECTED', {})
                .then((res) => {

                    if (res.data.status === 1) {
                        iniLoad()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document is rejected for shred."
                        })

                    } else {
                        func.addLog('sign', 'shredReject', res)

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                })
                .catch((error) => {
                    func.addLog('sign', 'shredReject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const shredApprove = () => {
            axios.put( '/signon/signee/shred/' + projId.value + '/' + docId.value + '/APPROVED', {})
                .then((res) => {

                    if (res.data.status === 1) {
                        iniLoad()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document is rejected for shred."
                        })

                    } else {
                        func.addLog('sign', 'shredReject', res)

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                })
                .catch((error) => {
                    func.addLog('sign', 'shredReject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const shredCancelReject = () => {
            axios.put( '/signon/signee/shred/' + route.params.projectId + '/' + route.params.documentId + '/CANCELREJECTED', {})
                .then((res) => {

                    if (res.data.status === 1) {
                        iniLoad()
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Document is cancelled for reject shred."
                        })

                    } else {
                        func.addLog('sign', 'shredCancelReject', res)

                        alert.value.push({
                            class: "danger",
                            title: "ERROR",
                            message: func.getError(res.data)
                        })

                    }
                })
                .catch((error) => {
                    func.addLog('sign', 'shredCancelReject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const cancelReject = () => {
            axios.put( '/signon/signee/' + projId.value + '/' + docId.value + '/CANCELREJECTED', {})
                .then((res) => {
                    console.info('cancel reject res', res.data.status, JSON.stringify(res))
                    if (res.data.status === 1) {
                        
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Rejected status is removed."
                        })

                    } else {
                        func.addLog('info', 'cancelReject', res)
                        if (res && res.data !== null && res.data !== undefined) {
                           
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data) + ' ' + res.data.description
                            })
                        }
                    }
                })
                .catch((error) => {
                    func.addLog('info', 'cancelReject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })
        }

        const delProj = () => {
            axios.delete( '/signon/' + projId.value)
                .then((res) => {
                    console.info('del res', res.data.status, JSON.stringify(res))
                    if (res.data.status === 1) {
                        
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Folder is shred."
                        })

                        router.push({ path: '/dashboard' })

                    } else {
                        func.addLog('info', 'delProj', res)

                        if (res && res.data !== null && res.data !== undefined) {

                            if (res.data.description === 'Folder is not WIP and flagged as CIRCULATING') {

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'This folder in CIRCULATING status and cannot be shredded unless you view it as Editor to modify it.'
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data) + ' ' + res.data.description
                                })
                            }
                            

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('info', 'delProj - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                })

        }

        const resendActivation = () => {
            const p = {
                username: email.value,
                refId: refIdResend.value,
                captcha: captchaResend.value,
                frontendUrl: '/auth/activate'
            } 

            axios.post('/auth/signup/resend', p)
                .then((res) => {
                    if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Activation email is sent, please wait 5 minutes and check your junk/spam folder."
                        })

                    } else {
                        func.addLog('info', 'resendActivation', res)
                        
                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('info', 'resendActivation - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }

        const getCaptchaResend = () => {
            axios.get('/reference/captcha/RACTIVATE')
                .then((res) => {
                    if (res.data.status === 1) {
                        captchaImgResend.value = res.data.data.captcha.image
                        refIdResend.value = res.data.data.refId

                    } else {
                        func.addLog('info', 'getCaptchaResend', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('info', 'getCaptchaResend - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
        }
        
        const addNote = () => {
            const p = {
                remark: publicNote.value,
                note: selfNote.value
            }

            axios.put( '/signon/' + projId.value + '/' + docId.value, p)
                .then((res) => {

                    if (res.data.status === 1) {
                        getProject(projId.value)

                        if (pageView.value === 'proj') {
                            getDocument(docId.value, false)

                        } else {
                            getDocument(docId.value, true)

                        }

                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Note is updated."
                        })

                    } else {
                        func.addLog('info', 'addNote', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('info', 'addNote - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                })
        }

        const proceedModify = () => {
            axios.put( '/signon/' + projId.value + '/wip', {})
                .then((res) => {

                    if (res.data.status === 1) {
                        router.push('/uploadDoc/' + projId.value)

                    } else {
                        func.addLog('info', 'proceedModify', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('info', 'proceedModify - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                   
                })
        }

        const sealFolder = () => {
            axios.put( '/signon/' + projId.value + '/sealed')
                .then((res) => {

                    if (res.data.status === 1) {
                        getProject(projId.value)
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: "Folder is sealed."
                        })

                    } else {
                        func.addLog('info', 'sealFolder', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('info', 'sealFolder - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                   
                })
        }

        const chkIsLogin = async () => {

            try {
                const res = await axios.get('/auth/session')

                if( res.data.status === 1) {
                    isLogin.value = true
                    userStatus.value = res.data.data.status

                } else {
                    isLogin.value = false

                    if (res && res.data !== null && res.data !== undefined) {
                        if (res.data.message !== 'no_session' ) {
                            func.addLog('login', 'chkSession', res)

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }
                }

            } catch (error) {
                isLogin.value = false
                func.addLog('info', 'chkIsLogin - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

            }
        }

        const downloadFolder = async () => {
            console.info('downloadFolder')

        }

        const downloadDoc = async () => {
            /* const existingPdfBytes = await fetch(doc.value.jFile.dlUrl, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const link = document.createElement("a")
            link.href = URL.createObjectURL(blob)
            link.download = doc.value.jFile.name
            link.click()
            URL.revokeObjectURL(link.href) */

            const existingPdfBytes = await fetch(doc.value.jFile.dlUrl, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = doc.value.jFile.name

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)
            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
        }

        const downloadAuditLog = async () => {
            /* const existingPdfBytes = await fetch(doc.value.jFile.dlAuditRptUrl, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const link = document.createElement("a")
            link.href = URL.createObjectURL(blob)
            link.download = doc.value.jFile.name.substring(0, doc.value.jFile.name.length-4) + '_AuditLog.pdf'
            link.click()
            URL.revokeObjectURL(link.href) */

            const existingPdfBytes = await fetch(doc.value.jFile.dlAuditRptUrl, { credentials: 'include' }).then(res => res.arrayBuffer())
            const blob = new Blob([existingPdfBytes], { type: "application/pdf" })
            const filename = doc.value.jFile.name.substring(0, doc.value.jFile.name.length-4) + '_AuditLog.pdf'

            if (window.navigator['msSaveOrOpenBlob']) {
                window.navigator['msSaveBlob'](blob, filename)

            }
            else {
                const link = window.document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = filename
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)

            }
           
        }

        const showDoc = (id) => {
            docId.value = id
            getDocument(id, true)

        }

        const getProject = async (id) => {
            if (id === undefined) {
                isLoadProjs.value = true

            } else {
                proj.value = {}
                const tmpDoc = []
                const tmpDocDel = []

                try {
                    projOwnerEditor.value = []

                    const res = await axios.get('/signon/signee/' + id)
                    if (res.data.status === 1) {
                        // console.info('getProj', JSON.stringify(res.data.data))
                        privacy.value = res.data.data.privacy
                        proj.value = res.data.data

                        if (res.data.data.jDocument !== undefined) {
                            // Add project.documents.progress to display in progress bar
                            res.data.data.jDocument.forEach((item, idx) => {

                                if (item.jSignee !== undefined) {
                                    if (item.jSignee.length === 0) {
                                        proj.value.jDocument[idx].progress = 0

                                    } else {
                                        let totalSignee = item.jSignee.length
                                        let totalSigned = 0

                                        item.jSignee.forEach((sItem, sIdx) => {
                                            if (sItem.signingStatus === 'SIGNED' || sItem.signingStatus === 'APPROVED' || sItem.signingStatus === 'REJECTED') {
                                                totalSigned++
                                            }

                                            /* if (sItem.signingStatus === 'DELETE' ) {
                                                hasDelDoc.value = true
                                            } */
                                        })

                                        setTimeout(() => {
                                            if (totalSigned === 0) {
                                                proj.value.jDocument[idx].progress = 0

                                            } else {
                                                proj.value.jDocument[idx].progress = ((totalSigned/totalSignee) * 100).toFixed(0) * 1

                                            }
                                        })
                                    }

                                    if(item.status === 'DELETE') {
                                        tmpDocDel.push(item)
                                    } else {
                                        totalDoc.value++
                                        tmpDoc.push(item)
                                    }
                                }

                            })

                            setTimeout(() => {
                                const tmp = tmpDoc.concat(tmpDocDel)
                                proj.value.jDocument = []
                                proj.value.jDocument = tmp
                                // console.info('proj.value.jDocument', proj.value.jDocument)
                            }, 500)
                        }

                        if (res.data.data.jSignee !== undefined) {
                            // console.info('getProject jSignee', JSON.stringify(res.data.data.jSignee))

                            res.data.data.jSignee.forEach((s, sIdx) => {
                                if (s.privilege === 'OWNER') {
                                    projOwnerEditor.value.push(s)
                                }
                            })

                            res.data.data.jSignee.forEach((s, sIdx) => {
                                if (s.privilege === 'EDITOR') {
                                    projOwnerEditor.value.push(s)
                                }
                            })
                        }

                        isLoadProjs.value = true

                    } else {
                        proj.value = null
                        isLoadProjs.value = true
                        func.addLog('info', 'getProject', res)

                        if (res && res.data !== null && res.data !== undefined) {

                            if (res.data.message === 'no_permission') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Permission denied.'
                                })

                            } else if (res.data.message === 'folder_not_found') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Folder not found.'
                                })
                            
                            } else if (res.data.message === 'no_session') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Login required.'
                                })

                                setTimeout(() => {
                                    router.push({ name: 'Login', query: { redirect: 'ViewProj', p: id } })
                                }, 500)

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }
                        }
                    }
                } catch (error) {
                    isLoadProjs.value = true
                    func.addLog('info', 'getProject - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                }
            }
        }

        const getDocument = async (id, hasFolderSignee) => {
            if (id === undefined) {
                isLoadProjs.value = true

            } else {
                try {

                    const res = await axios.get('/signon/signee/document/' + id)
                    if (res.data.status === 1) {
                        doc.value = res.data.data
                        // console.info('doc', json.stringify(doc.value))
                        publicNote.value = doc.value.remark
                        selfNote.value = doc.value.note

                        docShredApproval.value = res.data.data.jRemoval.status
                        docShredApprovalDate.value = res.data.data.jRemoval.dateAction
                    
                        if (doc.value) {
                            if (doc.value.jSignee !== undefined) {
                                let totalSignee = doc.value.jSignee.length
                                let totalSigned = 0

                                doc.value.jSignee.forEach((item) => {
                                    if (item.signingStatus === 'SIGNED' || item.signingStatus === 'APPROVED' || item.signingStatus === 'REJECTED') {
                                        totalSigned++
                                    }
                                })

                                if (totalSigned === 0) {
                                    doc.value.progress = 0

                                } else {
                                    doc.value.progress = ((totalSigned/totalSignee) * 100).toFixed(0) * 1

                                }
                            }
                        }

                        if (hasFolderSignee === true) {
                            getDocViewer()
                        } 

                    } else {
                        isLoadProjs.value = true
                        func.addLog('info', 'getDocument', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'document_not_found') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'Document not found.'
                                })

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }
                            
                        }
                    }

                } catch (error) {
                    isLoadProjs.value = true
                    func.addLog('info', 'getDocument - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                    
                }
            }
        }

        const getDocViewer = async () => {
            docViewer.value = []
            if (proj.value.jSignee && proj.value.jSignee.length > 0) {
                // console.info('proj signee', JSON.stringify(proj.value.jSignee))
                for (var p = 0; p < proj.value.jSignee.length; p++) {
                    // Skip owner and editor from viewer
                    if (proj.value.jSignee[p].privilege !== 'OWNER' && proj.value.jSignee[p].privilege !== 'EDITOR') {

                        let isSigneeExists = false
                        if (doc.value.jSignee && doc.value.jSignee.length > 0) {
                            for (var d = 0; d < doc.value.jSignee.length; d++) {
                                // Chk if folder signee exists in doc signee
                                if ( proj.value.jSignee[p].signeeId === doc.value.jSignee[d].signeeId) {
                                    isSigneeExists = true
                                }
                            }

                            if (isSigneeExists === false) {
                                docViewer.value.push(proj.value.jSignee[p])
                            }

                        } else {
                            // no doc signee means all folder signee are doc viewer
                            docViewer.value.push(proj.value.jSignee[p])
                        }
                    }
                } // for
            }
        }

        const droppedFile = async (e) => {
            // Drag file should be one file ya, for verification
            const files = ref([])
            files.value = e.dataTransfer.files

            for (var i = 0; i < files.value.length; i++) {
                num.value ++

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: 'include',
                    onUploadProgress: (progressEvent) => {
                        // progress for all files instaed of individual file
                        fPProgress.value = 0
                        fPProgress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                }

                let formData = new FormData()
                formData.append('file', files.value[i])

                await axios.post( '/signon/verify/document/' + docId.value, formData, config)
                    .then((res) => {

                        if (res.data.status === 1) {
                            fPResult.value = "Fingerprint matches with the Original Signed Version."
                            showFPResult.value = true
                            showFPUpload.value = false

                        } else {
                            func.addLog('info', 'droppedFile', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                if (res.data.message === 'invalid_format') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Invalid file format.'
                                    })

                                } else if (res.data.message === 'fingerprint_unmatched') {
                                    showFPResult.value = true
                                    showFPUpload.value = false

                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Wrong fingerprint. Either you uploaded wrong file or your file is tampered.'
                                    })

                                } else if (res.data.message === 'no_fingerprint') {
                                    showFPResult.value = true
                                    showFPUpload.value = false

                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'No fingerprint.'
                                    })

                                } else {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: func.getError(res.data)
                                    })

                                }
                            }
                        }
                        
                    })
                    .catch((error) => {
                        func.addLog('info', 'droppedFile - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                    })
            }
        }

        const selectedFile = async (e) => {
            const files = ref({})
            files.value = document.querySelector('.dropzoneFile').files

            for (var i = 0; i < files.value.length; i++) 
            {
                num.value ++
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    withCredentials: 'include',
                    onUploadProgress: (progressEvent) => {
                        // progress for all files instaed of individual file
                        fPProgress.value = 0
                        fPProgress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    }
                }

                let formData = new FormData()
                formData.append('file', files.value[i])

                await axios.post( '/signon/verify/document/' + docId.value, formData, config)
                    .then((res) => {

                        if (res.data.status === 1) {
                            fPResult.value = "Fingerprint matches with the Original Signed Version."
                            showFPResult.value = true
                            showFPUpload.value = false

                        } else {
                            func.addLog('info', 'selectedFile', res)

                            if (res && res.data !== null && res.data !== undefined) {
                                if (res.data.message === 'invalid_format') {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Invalid file format.'
                                    })

                                } else if (res.data.message === 'fingerprint_unmatched') {
                                    showFPResult.value = true
                                    showFPUpload.value = false

                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'Wrong fingerprint. Either you uploaded wrong file or your file is tampered.'
                                    })

                                } else if (res.data.message === 'no_fingerprint') {
                                    showFPResult.value = true
                                    showFPUpload.value = false

                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: 'No fingerprint.'
                                    })

                                } else {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: func.getError(res.data)
                                    })

                                }
                            }
                        }
                    })
                    .catch((error) => {
                        func.addLog('info', 'selectedFile - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                        
                    })
            } // for 
        }

        const shareFolder = () => {
            const copyText = 'https://app.signon.my/p/' + projId.value
            const tmpTextarea  = document.createElement('textarea')

            tmpTextarea.textContent = copyText
            document.body.append(tmpTextarea)
            tmpTextarea.select()
            const res = document.execCommand('copy')
            tmpTextarea.remove()

            if (res === true) {
                alert.value.push({
                    class: "info",
                    title: "INFO",
                    message: "Copied to clipboard."
                })
            }
        }

        onMounted(async () => {
            // console.info('onmounted')
            // fixed return from signing page not scrollable
            document.body.style.overflow = 'scroll'
            isCookieEnable.value = (navigator.cookieEnabled === true ? true : false)

            if (func.isEmptyObject(localStorage.getItem('entity')) === false) {
                let objLSEntity = func.decodeStrToJsonSimple(localStorage.getItem('entity'))
                if ( objLSEntity.entityId === '1') {
                    isSignOn.value = true
                }
            }

            email.value = store.getUserId.value

            chkIsLogin()
            projId.value = route.params.projectId
            docId.value = route.params.documentId

            // Close modal modify will set pagePrivilege to signee
            mdlModify.value.addEventListener("hidden.bs.modal", function (event) {
                pagePrivilege.value = 'signee'
            })

           if (projId.value === undefined) {
                pageMode.value = 'doc'
                pageView.value = 'doc'
                showDocId.value = docId.value

                await getDocument(docId.value, false)
                projId.value = doc.value.folderId
                await getProject(projId.value)

                // Set default pagePrivilege
                if (doc.value.privilege === 'EDITOR' || doc.value.privilege === 'OWNER' || doc.value.privilege === 'SIGNEE' || doc.value.privilege === 'VIEWER') {
                    pagePrivilege.value = 'signee'

                } else {
                    pagePrivilege.value = 'public'

                }
                getDocViewer()
                
            } else if (docId.value === undefined) {

                docId.value = ''
                pageMode.value = 'proj'
                pageView.value = 'proj'

                await getProject(projId.value)

                // Set default pagePrivilege
                if (proj.value && (proj.value.privilege === 'EDITOR' || proj.value.privilege === 'OWNER' || proj.value.privilege === 'SIGNEE' || proj.value.privilege === 'VIEWER')) {
                    pagePrivilege.value = 'signee'

                } else {
                    pagePrivilege.value = 'public'
                    
                }
            }

        })

        return {
            axios, route, router, alert, store, closeAlert, func, isSignOn, 
            isLogin, userStatus, privacy, isLoadProjs, showDoc,  
            projId, docId, pageMode, pageView, pagePrivilege, proj, doc, projName, /* hasDelDoc, */ totalDoc, 
            showSignee, showRejected, downloadFolder, downloadAuditLog, getUserId, 
            droppedFile, selectedFile, showViewer, projOwnerEditor, docViewer, 
            num, showFPUpload, showFPResult, fPResult, fPProgress, sealFolder, isCookieEnable,
            proceedModify, shareFolder, noteType, noteDesc, mdlModify, publicNote, selfNote, addNote, 
            getCaptchaResend, resendActivation, captchaImgResend, refIdResend, captchaResend, email, showDocId, getDocument, delProj, 
            cancelReject, cancelRejectDocId, docShredApproval, docShredApprovalDate, shredReject, shredApprove, shredCancelReject
        }
    }
}
</script>

<style>
body {
    color: #6E777E;
    font-size: 14px;
    overflow: scroll;
    padding-bottom: 10px;
}

.infoContainer {
    max-width: 1100px;
    margin: 105px auto 70px auto;
    border-radius: 10px;
}

.contentBox {
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 25px;
    margin-left: 2px;
    margin-right: 2px;
}

.whiteBox {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 25px;
    margin-left: 2px;
    margin-right: 2px;
    border-color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
}

.blueBox {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 25px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 25px;
    border-color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
    background-color: var(--bs-primary);
}

.greyBox {
    border-radius: 5px;
    padding: 10px;
    margin-left: 2px;
    margin-right: 2px;
    background-color: var(--auth-box-bg);
}

.tblSigneeList {
    padding-left: 45px;
}

@media (max-width: 575.98px) {
    .contentBox {
        border-radius: 10px;
    }

    .infoContainer {
        width: 95%;
        margin: 105px auto 20px auto;
    }

    .tblSigneeList {
        padding-left: 0px;
    }
}

.lblNote {
    font-weight: normal;
}

.lblNote:hover {
    font-weight: bold;
}

.lblNoteImg {
    width: 55px; 
    z-index: 9; 
    margin-bottom: 3px;
    margin-left: -44px; 
    cursor: pointer;
}

.lblNoteDesc {
    z-index: 10; 
    margin-top: -22px; 
    margin-left: -35px; 
    cursor: pointer;
}

</style>